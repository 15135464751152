import { createSlice } from "@reduxjs/toolkit";

const modal = createSlice({
  name: "modal",
  initialState: {
    feedbackModal : false,
    signinModal: false,
    isSignin: false,
  },
  reducers: {
    toggleFeedbackModal: (state) => {
      state.feedbackModal = !state.feedbackModal;
    },
    toggleSigninModal: (state) => {
      state.signinModal = !state.signinModal;
    }
  }
});

export const {toggleFeedbackModal, toggleSigninModal} = modal.actions;

export default modal.reducer;