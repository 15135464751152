import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

const defaultFetcher = retry(
	fetchBaseQuery({
		baseUrl: 'https://www.plutonn.com/api/',
		// baseUrl: 'http://localhost:3001/api/',
		credentials: 'include',
		prepareHeaders: headers => {
			headers.set('Content-Type', 'application/json');
			return headers;
		},
	}),
	{ maxRetries: 0 }
);

const api = createApi({
	reducerPath: 'api',
	baseQuery: defaultFetcher,
	tagTypes: [
		'usersignin',
		'usersignup',
		'user',
		'post',
		'post1',
		'get-messages',
		'send-message',
		'edit-message',
		'delete-message',
		'comment',
		'like',
		'following',
		'follower',
		'likes',
		'notifications',
		'unreadNotifications',
		'courses',
		'joined-community',
		'pinned-community',
		'community',
		'community-type',
		'trendingUsers',
		'trendingPosts',
		'searchHashtagsPosts',
		'searchPosts',
		'announcement',
		'get-members',
		'leave-community',
		'search-community',
		'all-community-by-user',
		'join-links',
		'searchUsers',
		'subscriptions',
		'users-feedbacks',
	],
	keepUnusedDataFor: 0,
	endpoints: () => ({}),
});

export default api;
