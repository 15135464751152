import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Icon } from '@iconify/react';
import Compressor from 'compressorjs';

import { useFollowMutation, useUnfollowMutation } from '@store/endpoints/network';
import { setActiveSection, updateActiveProfile } from '@store/reducers/profile';
import { useGetImageUploadUrlMutation } from '@store/endpoints/post';
import { useUpdateUserMutation } from '@store/endpoints/user';
import { toggleSigninModal } from '@store/reducers/modal';
import SocketContext from '@store/socket/Context';
import { RootState } from '@store/store';

import { User } from '$types/store/store';

import Image from '@components/partials/Avatar';
import numberFormat from '@utils/numberFormat';
import dev from '@configs/env.config.json';
import Button from '@components/buttons';

import styles from './style.module.scss';
import { updateUser } from '@store/reducers/user';

type ProfileHeaderProps = {
	className?: string;
	children: React.ReactNode;
};

const ProfileHeader = (props: ProfileHeaderProps) => {
	const profile = useSelector((state: any) => state.profile);
	const [search, setSearch] = useSearchParams();
	const { children, className } = props;
	const { username } = useParams();

	const currUser = useSelector<RootState, User | undefined>(state => state.user.user);
	const { socket } = useContext(SocketContext).SocketState;

	const [unfollow] = useUnfollowMutation();
	const [follow] = useFollowMutation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [getImage] = useGetImageUploadUrlMutation();
	const [updateProfile] = useUpdateUserMutation();

	const handleSave = (userData: any) => {
		if (userData) {
			const loadingToastId = toast.loading('Updating profile...');
			updateProfile(userData)
				.unwrap()
				.then((data: any) => {
					toast.update(loadingToastId, {
						render: 'Profile updated successfully! 👌',
						type: toast.TYPE.SUCCESS,
						isLoading: false,
						autoClose: 3000,
					});
					dispatch(updateActiveProfile({ ...userData }));
					dispatch(updateUser({ ...userData }));
				})
				.catch((error: any) => {
					toast.update(loadingToastId, {
						render: error.data.error,
						type: toast.TYPE.ERROR || 'Something went wrong!',
						isLoading: false,
						autoClose: 3000,
					});
				});
		}
	};

	const handleImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (file) {
			const extension = file.name.split('.').pop();
			const res = await getImage({ extension: extension ?? '' });
			new Compressor(file, {
				quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
				mimeType: 'image/webp',
				async success(compressedResult) {
					const { fileName, url } = (res as any).data;

					const response = await fetch(url, {
						method: 'PUT',
						headers: {
							'Content-Type': 'image/*',
						},
						body: compressedResult,
					});

					if (response.status !== 200) {
						toast.error('Something went wrong');
					} else {
						handleSave({ profile: dev.CLOUDFRONT_URL + fileName });
					}
				},
			});
		}

		setSearch({ type: 'profile' });
	};

	const handleFollow = (_id: string) => {
		if (!currUser) {
			dispatch(toggleSigninModal());
			return;
		}

		if (profile.activeProfile.isFollowing) {
			unfollow({ networkId: _id });
			//emiting unfollow event
			socket?.emit('users:unfollow', {
				userId: currUser?._id,
				entityId: _id,
			});
			dispatch(updateActiveProfile({ isFollowing: false }));
		} else {
			follow({ networkId: _id });
			//emiting follower event
			socket?.emit('users:follow', {
				userId: currUser?._id,
				entityId: _id,
			});
			dispatch(updateActiveProfile({ isFollowing: true }));
		}
	};

	const handleClicks = (type: any) => {
		dispatch(setActiveSection(type));
		if (type === 'edit' || type === 'activity/posts') navigate(`/profile/${username}/${type}`);
		else navigate(`/profile/${username}/network/${type}`);
	};

	return (
		<section className={className}>
			<div className={styles.header}>
				<section>
					<div>
						<Image
							src={profile.activeProfile?.profile || ''}
							alt={profile.activeProfile?.name || ''}
							loader='lg'
							errStyle={{ fontSize: '3rem' }}
							className={styles.avatar}
						/>
						{profile.activeProfile?._id === currUser?._id && (
							<label htmlFor='image' className={styles.pencil}>
								<Icon icon='ri:pencil-fill' />
								<input
									type='file'
									id='image'
									accept='image/*'
									onChange={handleImage}
									hidden
									style={{ display: 'none' }}
								/>
							</label>
						)}
					</div>
					<div className={styles.name}>
						<h3>@{profile.activeProfile?.username}</h3>
						<h2>{profile.activeProfile?.name}</h2>
					</div>
				</section>

				{profile.activeProfile?._id === currUser?._id ? (
					<Button
						className={styles.editBtn}
						text={profile.activeSection !== 'edit' ? 'Edit' : 'Back'}
						type={profile.activeSection !== 'edit' ? 'solid' : 'outline'}
						icon={profile.activeSection !== 'edit' ? 'edit' : 'ic:baseline-keyboard-double-arrow-left'}
						onClick={profile.activeSection !== 'edit' ? () => handleClicks('edit') : () => navigate(-1)}
					/>
				) : (
					<Button
						text={profile.activeProfile?.isFollowing ? 'Following' : 'Follow'}
						type='outline'
						icon={profile.activeProfile?.isFollowing ? 'tick' : 'plus'}
						className={`${styles.button} ${styles.btn}`}
						onClick={() => handleFollow(profile.activeProfile?._id)}
					/>
				)}
			</div>
			<div className={styles.stats}>
				<button className={styles.stat} onClick={() => handleClicks('activity/posts')}>
					<h2>{numberFormat(profile.activeProfile?.posts)}</h2>
					<h3>Posts</h3>
				</button>
				<button className={styles.stat} onClick={() => handleClicks('followers')}>
					<h2>{numberFormat(profile.activeProfile?.followers ?? 0)}</h2>
					<h3>Followers</h3>
				</button>
				<button className={styles.stat} onClick={() => handleClicks('followings')}>
					<h2>{numberFormat(profile.activeProfile?.followings ?? 0)}</h2>
					<h3>Following</h3>
				</button>
			</div>
			{children}
		</section>
	);
};

export default ProfileHeader;
